import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSpecialists(ctx, data) {
      return useJwt.getSpecialists(data).then(response => response);
    },
    createSpecialist(ctx, data) {
      return useJwt.createSpecialist(data).then(response => response);
    },
    updateSpecialist(ctx, data) {
      return useJwt.updateSpecialist(data).then(response => response);
    },
    deleteSpecialist(ctx, id) {
      return useJwt.deleteSpecialist(id).then(response => response);
    },
  },
};
